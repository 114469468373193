<template>
  <div
    class="border rounded-lg p-4 mt-8 cursor-pointer hover:border-primary"
    @click="$emit('select', company)"
  >
    <p>
      Company: <span class="italic">{{ company.name }}</span>
    </p>
    <p v-if="showFounder">
      Founder:
      <span class="italic">
        <a
          v-if="company.ceo?.linkedinUrl"
          :href="company.ceo.linkedinUrl"
          target="_blank"
          class="text-blue-600 dark:text-blue-500 hover:underline"
        >
          {{ company.ceo.fullName }}
        </a>
        <template v-else>
          {{ company.ceo?.fullName || 'N/A' }}
        </template>
      </span>
    </p>
    <p>
      {{ company.isPortfolioCompany ? 'Invested Date:' : 'Most Recent Opportunity Status:' }}
      <span class="italic">
        {{ formatCompanyOpportunityStatus(company) || 'N/A' }}
      </span>
    </p>
    <p class="mt-4">
      Description: <br />
      <span class="italic">
        {{ company.description || 'N/A' }}
      </span>
    </p>
  </div>
</template>

<script>
export default {
  name: 'CompanyOnePagerResultCard',
  props: {
    company: {
      required: true,
      type: Object,
    },
    showFounder: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    formatCompanyOpportunityStatus(company) {
      let formatted = company.mostRecentOpportunityStatus

      if (company.isPortfolioCompany && company.dateInvestedIn) {
        formatted += ' | '
        formatted += company.dateInvestedInMoment.year()
      }

      return formatted
    },
  },
}
</script>
