import { ModelAPI, ApiFilter } from '@thinknimble/tn-models'
import { objectToCamelCase, objectToSnakeCase } from '@thinknimble/tn-utils'
import { apiErrorHandler } from '../api'

import AxiosClient from '../AxiosClient'

// NOTE: The AxiosClient is congfigured to include '/api' in the baseUrl
const EMBEDDING_INDEX_ENDPOINT = '/embedding-indices/'

export default class EmbeddingIndexAPI extends ModelAPI {
  get client() {
    return AxiosClient
  }

  static ENDPOINT = EMBEDDING_INDEX_ENDPOINT

  static FILTERS_MAP = {
    ...EmbeddingIndexAPI.FILTERS_MAP,
    // filters
    hiddenFromUX: ApiFilter.create({ key: 'hidden_from_ux' }),
  }

  getByName = (name) => {
    const endpoint = EMBEDDING_INDEX_ENDPOINT + `by-name/`
    return this.client
      .get(endpoint, { params: { name } })
      .then((response) => this.cls.fromAPI(response.data))
      .catch(apiErrorHandler({ apiName: 'EmbeddingIndexAPI.getByName' }))
  }

  query = (indexId, content = '', maxNumResults = 20, filters = {}) => {
    const endpoint = EMBEDDING_INDEX_ENDPOINT + `${indexId}/query/`

    const data = {
      content,
      maxNumResults,
      filters: objectToSnakeCase(filters),
    }

    return AxiosClient.post(endpoint, objectToSnakeCase(data))
      .then((r) => {
        const data = { ...r.data }

        data.results = data.results.map((i) => {
          const item = objectToCamelCase(i)
          if (item.investments) {
            item.investments = item.investments.map(objectToCamelCase)
          }
          return item
        })

        return data
      })
      .catch(apiErrorHandler({ apiName: 'EmbeddingIndexAPI.query' }))
  }
}
