import Model, { fields } from '@thinknimble/tn-models'

import EmbeddingIndexAPI from './api'

export default class EmbeddingIndex extends Model {
  static api = EmbeddingIndexAPI.create(EmbeddingIndex)

  static id = new fields.CharField({ readOnly: true })
  static name = new fields.CharField()
  static contentTypeModelName = new fields.CharField({ readOnly: true })
  static isCompanyIndex = new fields.BooleanField()
  static isContactIndex = new fields.BooleanField()
}
