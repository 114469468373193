<template>
  <div class="flex flex-col items-center w-full">
    <h1 class="text-3xl heading font-bold text-center mb-8 md:mb-12">Company Lookup</h1>
    <div v-if="!showCreateOnePagerUI" class="text-left flex flex-col mb-12 w-full p-4 md:w-[38%]">
      <div class="inline-flex rounded text-xs mb-8" role="group">
        <button
          type="button"
          class="font-semibold py-3 px-8 border border-gray-200 rounded-l-lg cursor-pointer one-pager-select-search-by-founder-button"
          :class="{
            'bg-white text-black': searchBy !== 'FOUNDER',
            'bg-black text-white': searchBy === 'FOUNDER',
          }"
          @click="handleSelectSearchBy('FOUNDER')"
        >
          Search By Founder CEO
        </button>
        <button
          type="button"
          class="font-semibold py-3 px-8 border border-gray-200 cursor-pointer one-pager-select-search-by-company-button"
          :class="{
            'rounded-r-lg': !embeddingIndexId,
            'bg-white text-black': searchBy !== 'COMPANY',
            'bg-black text-white': searchBy === 'COMPANY',
          }"
          @click="handleSelectSearchBy('COMPANY')"
        >
          Search By Company
        </button>
        <button
          v-if="embeddingIndexId"
          type="button"
          class="font-semibold py-3 px-8 border border-gray-200 rounded-r-lg cursor-pointer one-pager-select-search-by-description-button"
          :class="{
            'bg-white text-black': searchBy !== 'DESCRIPTION',
            'bg-black text-white': searchBy === 'DESCRIPTION',
          }"
          @click="handleSelectSearchBy('DESCRIPTION')"
        >
          Search By Description
        </button>
      </div>
      <template v-if="searchBy === 'FOUNDER'">
        <label class="font-bold mb-1">Search By Founder CEO</label>
        <p class="text-xs mb-2">Lookup any company in Salesforce using founder CEO name</p>
        <VueMultiselect
          v-model="selectedFounder"
          searchable
          closeOnSelect
          label="fullName"
          trackBy="id"
          placeholder="Founder name"
          :options="optionsSearchByFounder"
          :loading="loadingSearchByFounder"
          :showLabels="false"
          :internalSearch="false"
          :clearOnSelect="false"
          :allowEmpty="false"
          :showNoOptions="false"
          :optionsLimit="20"
          :multiple="false"
          @select="handleFounderSelected"
          @searchChange="debouncedSearchByFounder"
        >
          <template #noResult>
            <span>Oops! No companies found.</span>
          </template>
        </VueMultiselect>

        <LoadingSpinner v-if="selectedFounder && !selectedFounderCompanies.length" class="mt-12" />

        <div v-if="selectedFounderCompanies.length" class="text-sm md:text-base">
          <CompanyOnePagerResultCard
            v-for="company in selectedFounderCompanies"
            :key="company.id"
            :company="company"
            @select="(c) => (selectedCompany = c)"
          />
        </div>
      </template>
      <template v-if="searchBy === 'COMPANY'">
        <label class="font-bold mb-1">Search By Company</label>
        <p class="text-xs mb-2">Lookup any company in Salesforce using company name</p>
        <VueMultiselect
          v-model="selectedCompany"
          searchable
          closeOnSelect
          label="companyNameWithCeoName"
          trackBy="id"
          placeholder="Company name"
          :options="optionsSearchByCompany"
          :loading="loadingSearchByCompany"
          :showLabels="false"
          :internalSearch="false"
          :clearOnSelect="false"
          :allowEmpty="false"
          :showNoOptions="false"
          :optionsLimit="20"
          :multiple="false"
          @searchChange="debouncedSearchByCompany"
          @select="(company) => (selectedCompany = company)"
        >
          <template #noResult>
            <span>Oops! No companies found.</span>
          </template>
        </VueMultiselect>

        <div class="flex justify-end mt-12">
          <button
            :disabled="!selectedCompany"
            class="bg-white text-black font-semibold py-3 px-8 rounded border-gray drop-shadow-sm border one-pager-search-by-company-button"
            :class="{
              'cursor-pointer': selectedCompany,
              'cursor-not-allowed': !selectedCompany,
            }"
            @click="showCreateOnePagerUI = true"
          >
            Next
          </button>
        </div>
      </template>
      <template v-if="searchBy === 'DESCRIPTION'">
        <TextAreaField
          v-model:value="description"
          label="Search By Description"
          :description="`Search for companies in Salesforce using AI-powered description search. Search term must be at least ${this.descriptionMinCharacters} characters to ensure relevancy.`"
          placeholder="Company description..."
        />

        <div class="flex justify-end mt-12">
          <button
            class="bg-white text-black font-semibold py-3 px-8 rounded border-gray drop-shadow-sm border cursor-pointer one-pager-search-by-description-button"
            :class="{
              'cursor-pointer': isDescriptionValid && !queryingEmbeddingIndex,
              'cursor-not-allowed': !isDescriptionValid || queryingEmbeddingIndex,
            }"
            :disabled="!isDescriptionValid || queryingEmbeddingIndex"
            @click="searchByDescription"
          >
            Next
          </button>
        </div>
        <LoadingSpinner v-if="queryingEmbeddingIndex" class="mt-12" />

        <div
          v-if="!queryingEmbeddingIndex && optionsSearchByDescription.length"
          class="text-sm md:text-base"
        >
          <CompanyOnePagerResultCard
            v-for="company in optionsSearchByDescription"
            :key="company.id"
            :company="company"
            @select="(c) => (selectedCompany = c)"
            showFounder
          />
        </div>
      </template>
    </div>
    <div v-else class="text-left flex flex-col w-full box-border md:w-[38%]">
      <div class="flex flex-col max-w-5xl w-full rounded-lg p-4 mb-4">
        <p>
          Company: <span class="italic">{{ selectedCompany.name }}</span>
          <span v-if="selectedCompany.website" class="ml-0.5"
            >(
            <a
              :href="selectedCompany.formattedWebsite"
              target="_blank"
              class="text-blue-600 dark:text-blue-500 hover:underline -mx-1 one-pager-company-website-link"
            >
              website
            </a>
            )
          </span>
        </p>
        <p>
          Founder:
          <span class="italic">
            <a
              v-if="selectedCompany.ceo?.linkedinUrl"
              :href="selectedCompany.ceo.linkedinUrl"
              target="_blank"
              class="text-blue-600 dark:text-blue-500 hover:underline one-pager-ceo-linkedin-url"
            >
              {{ selectedCompany.ceo.fullName }}
            </a>
            <template v-else>
              {{ selectedCompany.ceo?.fullName || 'N/A' }}
            </template>
          </span>
        </p>
        <p>
          {{
            selectedCompany.isPortfolioCompany ? 'Invested Date:' : 'Most Recent Opportunity Status'
          }}
          <span class="italic">
            {{ formatCompanyOpportunityStatus(selectedCompany) }}
          </span>
        </p>
        <p>
          Most Recent Opportunity Point:
          <span class="italic">
            {{ selectedCompany.mostRecentOpportunityPoint || 'N/A' }}
          </span>
        </p>
        <p class="mt-4">
          Description: <br />
          <span class="italic">
            {{ selectedCompany.description }}
          </span>
        </p>
      </div>
      <div class="flex flex-col justify-end md:mt-8 p-4">
        <button
          class="bg-primary text-white font-semibold py-3 px-8 rounded border-gray drop-shadow-sm border cursor-pointer grow md:grow-0 create-one-pager-button"
          @click="attemptCreateNotionOnePager()"
        >
          More
          <img :src="require('@/assets/FirstRoundLogoBlack.svg')" class="h-5 inline -mr-0.5 mb-1" />
          nfo
        </button>
        <button
          @click="showCreateOnePagerUI = false"
          class="bg-white text-black font-semibold py-3 px-8 rounded border-gray drop-shadow-sm border cursor-pointer mt-4 one-pager-back-button"
        >
          Back
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash.debounce'
import Company from '@/services/companies'
import Contact from '@/services/contacts'
import EmbeddingIndex from '@/services/embeddingIndices'
import TrackedEvent, { events } from '@/services/trackedEvents'
import { trackHeapEvent } from '@/services/metrics'

import VueMultiselect from 'vue-multiselect'
import LoadingSpinner from '@/components/LoadingSpinner'
import InputField from '@/components/inputs/InputField'
import TextAreaField from '@/components/inputs/TextAreaField'
import CompanyOnePagerResultCard from '@/components/Companies/CompanyOnePagerResultCard'

export default {
  name: 'CompanyOnePager',
  components: {
    VueMultiselect,
    LoadingSpinner,
    InputField,
    TextAreaField,
    CompanyOnePagerResultCard,
  },
  data() {
    return {
      searchBy: 'FOUNDER',
      selectedCompany: null,
      showCreateOnePagerUI: false,
      // search by founder -related
      optionsSearchByFounder: [],
      loadingSearchByFounder: false,
      selectedFounder: null,
      selectedFounderCompanies: [],
      // search by company -related
      optionsSearchByCompany: [],
      loadingSearchByCompany: false,
      // search by description -related
      embeddingIndexId: null,
      description: '',
      queryingEmbeddingIndex: false,
      optionsSearchByDescription: [],
      // tracked -related
      trackedSearchByFounder: false,
      trackedSearchByCompany: false,
      trackedSearchByDescription: false,
      descriptionMinCharacters: parseInt(
        process.env.VUE_APP_COMPANY_DESCRIPTION_MINIMUM_CHARACTERS,
      ),
    }
  },
  created() {
    const urlParams = new URLSearchParams(window.location.search)
    const companyId = urlParams.get('companyId')
    const description = urlParams.get('description')

    EmbeddingIndex.api.getByName(process.env.VUE_APP_COMPANY_ONE_PAGER_INDEX_NAME).then((index) => {
      this.embeddingIndexId = index.id
      if (description) {
        this.searchBy = 'DESCRIPTION'
        this.description = description
        this.searchByDescription()
      } else if (companyId) {
        this.searchByCompanyId(companyId)
      }
    })

    TrackedEvent.api.create(this.$store.getters.user.id, events.COMPANY_ONE_PAGER_PAGEVIEW)
  },
  methods: {
    handleSelectSearchBy(value) {
      this.searchBy = value
      this.resetSearchState()
      this.selectedCompany = null
    },
    resetSearchState() {
      // search by founder -related
      this.optionsSearchByFounder = []
      this.loadingSearchByFounder = false
      this.selectedFounder = null
      this.selectedFounderCompanies = []
      // search by company -related
      this.optionsSearchByCompany = []
      this.loadingSearchByCompany = false
    },
    searchByCompanyId(companyId) {
      this.loadingSearchByCompany = true
      Company.api.get(companyId).then((company) => {
        this.selectedCompany = company
        this.loadingSearchByCompany = false
      })
    },
    _searchByFounder(query) {
      if (!query) return

      this.loadingSearchByFounder = true
      const collection = Contact.createCollection({
        pagination: {
          size: 20,
          page: 1,
        },
        filters: {
          fullName: query,
          isCeoOfPortfolioOrProspectiveCompany: true,
        },
      })

      collection.refresh().then(() => {
        this.optionsSearchByFounder = collection.list
        this.loadingSearchByFounder = false
      })

      if (!this.trackedSearchByFounder) {
        this.trackedSearchByFounder = true
        trackHeapEvent('Company One Pager Search By Founder', {
          founder: query,
          userId: this.$store.getters.user.id,
          userEmail: this.$store.getters.user.email,
        })
        TrackedEvent.api.create(
          this.$store.getters.user.id,
          events.COMPANY_ONE_PAGER_SEARCH_BY_FOUNDER,
        )
      }
    },
    _searchByCompany(query) {
      if (!query) return

      this.loadingSearchByCompany = true
      const collection = Company.createCollection({
        pagination: {
          size: 20,
          page: 1,
        },
        filters: {
          name: query,
          hasAnyOfRecordTypeIds: [
            process.env.VUE_APP_PORTFOLIO_COMPANY_RECORD_TYPE_ID,
            process.env.VUE_APP_PROSPECTIVE_COMPANY_RECORD_TYPE_ID,
          ].join(','),
        },
      })

      collection.refresh().then(() => {
        this.optionsSearchByCompany = collection.list
        this.loadingSearchByCompany = false
      })

      if (!this.trackedSearchByCompany) {
        this.trackedSearchByCompany = true
        trackHeapEvent('Company One Pager Search By Company', {
          company: query,
          userId: this.$store.getters.user.id,
          userEmail: this.$store.getters.user.email,
        })
        TrackedEvent.api.create(
          this.$store.getters.user.id,
          events.COMPANY_ONE_PAGER_SEARCH_BY_COMPANY,
        )
      }
    },
    searchByDescription() {
      if (this.queryingEmbeddingIndex) return

      this.queryingEmbeddingIndex = true
      EmbeddingIndex.api
        .query(this.embeddingIndexId, this.description, 20)
        .then((data) => {
          if (data.results.length === 1) {
            this.selectedCompany = data.results[0]
            this.showCreateOnePagerUI = true
          } else {
            this.optionsSearchByDescription = data.results
          }
        })
        .finally(() => (this.queryingEmbeddingIndex = false))

      if (!this.trackedSearchByDescription) {
        trackHeapEvent('Company One Pager Search By Description', {
          description: this.description,
          userId: this.$store.getters.user.id,
          userEmail: this.$store.getters.user.email,
        })
        this.trackedSearchByDescription = true
        TrackedEvent.api.create(
          this.$store.getters.user.id,
          events.COMPANY_ONE_PAGER_SEARCH_BY_DESCRIPTION,
        )
      }
    },
    handleFounderSelected(contact) {
      this.selectedFounder = contact

      const collection = Company.createCollection({
        pagination: {
          size: 20,
          page: 1,
        },
        filters: {
          ceo: contact.id,
        },
      })
      collection.refresh().then(() => {
        this.selectedFounderCompanies = collection.list
      })
    },
    attemptCreateNotionOnePager() {
      this.$store.dispatch('setLoading', true)

      Company.api
        .createNotionOnePager(this.selectedCompany.id)
        .then(({ notionPageUrl }) => {
          trackHeapEvent('Company One Pager Create Notion Page', {
            companyId: this.selectedCompany.id,
            companyName: this.selectedCompany.name,
            userId: this.$store.getters.user.id,
            userEmail: this.$store.getters.user.email,
          })
          TrackedEvent.api.create(
            this.$store.getters.user.id,
            events.COMPANY_ONE_PAGER_CREATE_NOTION_PAGE,
            {
              companyId: this.selectedCompany.id,
              companyName: this.selectedCompany.name,
            },
          )

          const link = document.createElement('a')
          link.href = notionPageUrl
          link.target = '_blank'
          link.click()
        })
        .finally(() => this.$store.dispatch('setLoading', false))
    },
    formatCompanyOpportunityStatus(company) {
      let formatted = company.mostRecentOpportunityStatus

      if (company.isPortfolioCompany && company.dateInvestedIn) {
        formatted += ' | '
        formatted += company.dateInvestedInMoment.year()
      }

      return formatted
    },
  },
  computed: {
    debouncedSearchByFounder() {
      return debounce(this._searchByFounder, 760, { trailing: true })
    },
    debouncedSearchByCompany() {
      return debounce(this._searchByCompany, 760, { trailing: true })
    },
    isDescriptionValid() {
      return this.description.length >= this.descriptionMinCharacters
    },
  },
  watch: {
    selectedCompany(company) {
      this.showCreateOnePagerUI = !!company
    },
  },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
